import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { FaArrowRight, FaRupeeSign } from "react-icons/fa";
import { Button, ButtonToolbar } from 'rsuite';
import Ladder_Icon from "../../assets/ladder.png";

export default function NationPosition({ teams, isSmallScreen }) {

    const SUSPEND = "SUSPEND";
    const SUSPENDED = "SUSPENDED";
    const CLOSED = "CLOSED";
    const ACTIVE = "ACTIVE";
    const COMPLETED = "COMPLETED";
    const BALL_RUN = "BALL_RUN";
    const BALL_RUNNING = "Ball Running";
    const STARTING_SOON = "Starting soon.";

    const [showBetButtons, setShowBetButtons] = useState(false);
    const [selectedTeamIndex, setSelectedTeamIndex] = useState();
    const [backSelected, setBackSelected] = useState();
    const [betInputValue, setBetInputValue] = useState("");

    const player1Exposure = 100;
    const player2Exposure = 0;

    const backColor = "#90EE90";
    const layColor = "#F9A9BA";
    const posColor = "#F8F8F8";

    const bets = [
        "10",
        "50",
        "100",
        "250",
        "500",
        "750",
        "1000",
        "1250",
        "1500",
        "2000",
    ];

    var betBackgroundColor;
    const getBetBackgroundColorWithOpacity = () => {
        if (backSelected) {
            betBackgroundColor = backColor + "40";
        } else {
            betBackgroundColor = layColor + "40";
        }
        return betBackgroundColor;
    };

    // const handleBackBoxClick = (e, index, rate, type, marketId) => {
    //     // Toggle the visibility of the bet buttons box
    //     e.preventDefault();
    //     if (!backSelected) {
    //         setBackSelected(true);
    //     } else {
    //         setShowBetButtons(!showBetButtons);
    //         setBackSelected(true);
    //     }
    //     setCandidate(type);
    //     setCandidateRate(rate);
    //     setCandidateMarketId(marketId);
    //     setSelectedTeamIndex(index);
    //     console.log("backSelected", type, rate, marketId);
    //     // setInputValue("");
    //     // setReturnsTeam1(0.0);
    //     // setReturnsTeam2(0.0);
    //     // setBetType("BACK");
    //     // setRate(teams.at(index).backRate);
    // };

    // const handleLayBoxClick = (e, index, rate, type, marketId) => {
    //     // Toggle the visibility of the bet buttons box
    //     e.preventDefault();
    //     if (backSelected) {
    //         setBackSelected(false);
    //     } else {
    //         setShowBetButtons(!showBetButtons);
    //         setBackSelected(true);
    //     }
    //     setCandidate(type);
    //     setCandidateRate(rate);
    //     setCandidateMarketId(marketId);
    //     setSelectedTeamIndex(index);
    //     console.log("backSelected", type, rate, marketId);
    //     // setInputValue("");
    //     // setReturnsTeam1(0.0);
    //     // setReturnsTeam2(0.0);
    //     // setBetType("LAY");
    //     // setRate(teams.at(index).layRate);
    // };

    // const handleBetButtonClick = (bet, index) => {
    //     // Set the selected bet and hide the bet buttons box
    //     // setInputValue(bet);
    //     // showAndSetReturns(index, bet);
    //     setBetInputValue(bet);
    // };

    // const handleBetInputChange = (event, index) => {
    //     // only allow numeric value
    //     // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    //     // setInputValue(numericValue);
    //     // showAndSetReturns(index, numericValue);
    //     const b = event.target.value;
    //     setBetInputValue(b);
    // };

    // const [showLoader, setShowLoader] = useState(false);
    // const handlePlaceBetClick = (e, team) => {
    //     console.log("place bet", team.name);
    //     setShowLoader(true);
    // }

    // const placeBet = (team) => {
    //     console.log("place bet api call!", team);
    //     setSuccessful(false);
    //     console.log("place bet", candidateMarketId, candidate, betInputValue, "odds", candidateRate);
    //     dispatch(placeCricBet(candidateMarketId, candidate, betInputValue, "odds", candidateRate))
    //         .then((data) => {
    //             console.log("edit agent data: ", data);
    //             if (data.status === 401) {
    //                 if (data.data === "Wrong password") {
    //                     setSuccessful(false);
    //                 } else {
    //                     logout();
    //                 }
    //             } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
    //                 setSuccessful(false);
    //             } else if (data.status === 200) {
    //                 setSuccessful(true);
    //             }
    //         })
    //         .catch(() => {
    //         });
    //     setShowLoader(false);
    // }

    return (
        <div>
            {/* {showLoader && <div className="overlay">
                <div className="overlay-content">
                    <BetPlaceLoader time={5} laps={1} placeBetFunction={placeBet} /></div>
            </div>} */}
            {teams.map((team, index) => (
                <div key={index}>
                    {team.status.toUpperCase() !== COMPLETED.toUpperCase() && <Card className="team-card" square={true}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "stretch",
                                flexDirection: "row",
                                backgroundColor: "#f8f8f8",
                                textAlign: "left",
                            }}
                        >
                            {/* Team Name (70% width) */}
                            <Box flex={isSmallScreen ? 0.53 : 0.55} marginRight="2px" sx={{ padding: "2px", paddingTop: "12px", paddingBottom: "12px", fontSize: "13px", paddingLeft: "4px" }}
                                key={index}
                                className="flash">
                                <Typography variant="h7" component="div">
                                    {team.marketName}
                                </Typography>
                                {/* <div
                                    style={{
                                        display: "flex",
                                        color: "#D0011C",
                                        marginTop: "10px",
                                    }}
                                >
                                    <FaArrowRight
                                        color="#D0011C"
                                        style={{ fontSize: "18px" }}
                                    />
                                    <Typography
                                        variant="h7"
                                        component="div"
                                        display={{ marginLeft: "3px", fontWeight: "bold" }}
                                    >
                                        {index === 0 && (
                                            <span
                                                style={{
                                                    color: player1Exposure >= 0 ? "green" : "#D0011C",
                                                }}
                                            >
                                                {player1Exposure}
                                            </span>
                                        )}
                                        {index === 1 && (
                                            <span
                                                style={{
                                                    color: player2Exposure >= 0 ? "green" : "#D0011C",
                                                }}
                                            >
                                                {player2Exposure}
                                            </span>
                                        )}
                                    </Typography>
                                </div> */}
                            </Box>
                            {/* Back Section (15% width) */}
                            {team.status.toUpperCase() === ACTIVE.toUpperCase() && <Box
                                flex={0.12}
                                backgroundColor={backColor}
                                style={{
                                    // background: "rgb(40 108 79 / 60%)",
                                    background: "#F9A9BA",
                                    padding: "10px",
                                }}
                                position="relative"
                                // onClick={(e) => handleBackBoxClick(e, index, team.backRate, "back", team.marketId)}
                                key={team.noRate + team.noValue + index + "no"}
                                className="flash-yellow"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "30%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                        fontWeight: "200",
                                    }}
                                >
                                    <b>{team.noRate}</b>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "70%", // Adjusted position for the third Typography element
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                    }}
                                >
                                    {team.yesValue}
                                </Typography>
                            </Box>}
                            {(team.status.toUpperCase() === SUSPENDED.toUpperCase() || team.status.toUpperCase() === SUSPEND.toUpperCase()
                                || team.status.toUpperCase() === CLOSED.toUpperCase() || team.status.toUpperCase() === BALL_RUNNING.toUpperCase() || team.status.toUpperCase() === BALL_RUN.toUpperCase()
                                || team.status.toUpperCase() === STARTING_SOON.toUpperCase() || team.status.length === 0) && <Box
                                    flex={0.12}
                                    backgroundColor={backColor}
                                    style={{
                                        background: "rgb(40 108 79 / 60%)",
                                        padding: "9px",
                                        backgroundColor: "white",
                                        opacity: "0.5",
                                        border: "0.1px solid red"
                                    }}
                                    position="relative"
                                // onClick={() => handleBackBoxClick(index)}
                                // key={team.backRate}
                                // className="flash"
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        style={{
                                            fontSize: "11px",
                                            textAlign: "center",
                                            position: "absolute",
                                            top: "50%", // Adjusted position for the third Typography element
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            margin: "0",
                                            color: (team.status.toUpperCase() === BALL_RUNNING.toUpperCase() || team.status.toUpperCase() === BALL_RUN.toUpperCase() || team.status.toUpperCase() === STARTING_SOON.toUpperCase()) ? "blue" : "red"
                                        }}
                                    >
                                        {team.status.length === 0 ? (SUSPENDED || SUSPEND) : team.status.replace("_", " ")}
                                    </Typography>
                                </Box>}
                            {/* Lay Section (15% width) */}
                            {team.status.toUpperCase() === ACTIVE.toUpperCase() && <Box
                                flex={0.12}
                                backgroundColor={layColor}
                                position="relative"
                                // onClick={(e) => handleLayBoxClick(e, index, team.layRate, "lay", team.marketId)}
                                style={{
                                    background: "rgb(40 108 79 / 60%)",
                                    padding: "10px",
                                }}
                                key={team.yesRate + team.yesValue + index + "yes"}
                                className="flash-blue"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "30%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                        color: "white",
                                    }}
                                >
                                    <b>{team.yesRate}</b>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "70%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                        color: "white",
                                    }}
                                >
                                    {team.yesValue}
                                </Typography>
                            </Box>}
                            {(team.status.toUpperCase() === SUSPENDED.toUpperCase() || team.status.toUpperCase() === SUSPEND.toUpperCase()
                                || team.status.toUpperCase() === CLOSED.toUpperCase() || team.status.toUpperCase() === BALL_RUNNING.toUpperCase() || team.status.toUpperCase() === BALL_RUN.toUpperCase()
                                || team.status.toUpperCase() === STARTING_SOON.toUpperCase() || team.status.length === 0) && <Box
                                    flex={0.12}
                                    backgroundColor={backColor}
                                    style={{
                                        background: "rgb(40 108 79 / 60%)",
                                        padding: "9px",
                                        backgroundColor: "white",
                                        opacity: "0.5",
                                        border: "0.1px solid red"
                                    }}
                                    position="relative"
                                // onClick={() => handleBackBoxClick(index)}
                                // key={team.backRate}
                                // className="flash"
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        style={{
                                            fontSize: "11px",
                                            textAlign: "center",
                                            position: "absolute",
                                            top: "50%", // Adjusted position for the third Typography element
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            margin: "0",
                                            color: (team.status.toUpperCase() === BALL_RUNNING.toUpperCase() || team.status.toUpperCase() === BALL_RUN.toUpperCase() || team.status.toUpperCase() === STARTING_SOON.toUpperCase()) ? "blue" : "red"
                                        }}
                                    >
                                        {team.status.length === 0 ? (SUSPENDED || SUSPEND) : team.status.replace("_", " ")}
                                    </Typography>
                                </Box>}
                            <Box
                                flex={0.12}
                                backgroundColor={posColor}
                                style={{
                                    fontSize: "12px",
                                    background: "rgb(40 108 79 / 60%)",
                                    padding: "9px",
                                    backgroundColor: "white",
                                    border: "0.1px solid gray"
                                }}
                                position="relative"
                            // onClick={() => handleBackBoxClick(index)}
                            // key={team.backRate}
                            // className="flash"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "50%", // Adjusted position for the third Typography element
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                        color: "black",
                                    }}
                                >
                                    {team.noPosition}
                                </Typography>
                            </Box>
                            <Box
                                flex={0.12}
                                backgroundColor={posColor}
                                style={{
                                    fontSize: "12px",
                                    background: "rgb(40 108 79 / 60%)",
                                    padding: "9px",
                                    backgroundColor: "white",
                                    border: "0.1px solid gray"
                                }}
                                position="relative"
                            // onClick={() => handleBackBoxClick(index)}
                            // key={team.backRate}
                            // className="flash"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "50%", // Adjusted position for the third Typography element
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        margin: "0",
                                        color: "black",
                                    }}
                                >
                                    {team.yesPosition}
                                </Typography>
                            </Box>
                            <Box
                                backgroundColor={posColor}
                                style={{
                                    width: "30px",
                                    Height: "100%",
                                    fontSize: "12px",
                                    // background: "rgb(40 108 79 / 60%)",
                                    backgroundColor: "white",
                                    border: "0.1px solid gray",
                                    verticalAlign: "center",
                                    // paddingTop: "10px",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // background: "purple",
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                                position="relative"
                            // onClick={() => handleBackBoxClick(index)}
                            // key={team.backRate}
                            // className="flash"
                            >
                                <div>
                                    <img src={Ladder_Icon} width={"20px"} style={{ padding: "auto" }} />
                                </div>
                            </Box>
                        </div>
                    </Card>}
                </div>
            ))}
        </div>
    );
}
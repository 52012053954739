import "../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { IconButton } from "rsuite";
import { Admin, Menu, Reload, Resize, Search } from '@rsuite/icons';
import { Button, ButtonToolbar } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
import TableHeader from "./TableHeader";
import './css/LiveMatches.css';
import DynamicTable from './tables/DynamicTable';
import { allMatches, allSeries, completedMatches } from './tables/Columns';
// import GameScreen from './GameScreen';
// import GameScreen2 from './GameScreen2';
// import data from "./data";
// import ReactPaginate from "react-paginate";
// import { httpHelpers } from "../../services/httpHelpers";
// import { 
//   ZERO,
//   ONE,
//   TWO,
//   THREE,
//   FOUR,
//   FIVE,
//   SIX,
//   SEVEN,
//   EIGHT,
//   NINE,
//   GREEN,
//   PURPLE,
//   RED
// } from '../LuckyNumber/BetCandidatesTypes';
import {
    CRICKET,
    TENNIS,
    FOOTBALL,
    LIVE_CASINO,
    VIRTUAL_CASINO,
    MATCH_STATUS_LIVE,
    MATCH_STATUS_UPCOMING,
    MATCH_STATUS_COMPLETED
} from "./common/Constants";
import TableTitle from "./customized/TableTitle";
import MatchesTable from "./tables/MatchesTable";

export default function LiveMatches({ role, logout, supportedSports, isSmallScreen }) {
    console.log("show", role);

    const href = window.location.href;
    let liveMatches = true;
    if (href.includes("compmatcheslist")) {
        liveMatches = false;
    }
    console.log("liveMatches", liveMatches);
    // const { state } = useLocation();
    // const { embedLink, games, sportId } = state ? state : "";
    const [sportType, setSportType] = useState("cricket");
    const [matchStatus, setMatchStatus] = useState();
    const getMatches = "/beta/getAllMatches?sportType=";
    // const getLiveGames = "http://64.227.161.65:8090/cricket/getAllMatches?seriesId=12602958";
    const api = httpHelpers();
    const [matches, setMatches] = useState(null);
    const [noMatches, setNoMatches] = useState(false);
    let navigate = useNavigate();

    const fetchMatches = () => {
        console.log("liveMatches", sportType, matchStatus)
        api
            .get(`${getMatches}` + sportType + "&matchStatus=" + matchStatus)
            .then(res => {
                console.log("fetch matches", res);
                if (res && res.data) {
                    if (res.data === "") {
                        setNoMatches(true);
                    } else {
                        setMatches(res.data);
                    }
                } else {
                    setMatches(null);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    const inPlayMatches = (e) => {
        e.preventDefault();
        setSportType(CRICKET);
        setMatchStatus(MATCH_STATUS_LIVE);
    };

    const upcomingMatches = (e) => {
        e.preventDefault();
        setSportType(CRICKET);
        setMatchStatus(MATCH_STATUS_UPCOMING);
    };

    const onSportClick = (e, sport) => {
        e.preventDefault();
        if (!sport.includes(LIVE_CASINO) && !sport.includes(VIRTUAL_CASINO))
            setSportType(sport);
    };

    useEffect(() => {
        if (matchStatus)
            fetchMatches();
    }, [sportType]);

    useEffect(() => {
        if (matchStatus)
            fetchMatches();
    }, [matchStatus]);

    useEffect(() => {
        if (!liveMatches) {
            setMatchStatus(MATCH_STATUS_COMPLETED);
        } else {
            setMatchStatus(MATCH_STATUS_LIVE);
        }
    }, [liveMatches]);

    return (
        <div>
            {noMatches && <div>
                <label style={{ color: "#48aaad", fontSize: "30px" }}>No Live Matches!</label></div>}
            <div>
                {/* <TableHeader title={"All Matches"} /> */}
                <TableTitle
                    text="All Matches"
                    color="#ffffff"
                    fontSize="14px"
                    textAlign="left"
                    width={isSmallScreen ? "97%" : "99.2%"}
                    height="42px"
                    marginLeft="5px"
                    marginRight="0px"
                    paddingLeft="10px"
                />
            </div>
            {liveMatches && <div className="cric-all-matches-button-container">
                <button className="cric-board-buttons board-buttons-nav-bar-dark-large"
                    style={{ marginLeft: "5px" }}
                    onClick={(e) => inPlayMatches(e)}>
                    Inplay Matches
                </button>
                <button className="cric-board-buttons board-buttons-nav-bar-dark-large"
                    style={{ marginLeft: "5px" }}
                    onClick={(e) => upcomingMatches(e)}>
                    Upcoming Matches
                </button>
            </div>}
            <div className="App" style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px", background: "white" }}>
                <div className="cric-sports-button-container">
                    {supportedSports.map((row, index) =>
                    (row !== LIVE_CASINO && row !== VIRTUAL_CASINO && <button className="cric-board-buttons board-buttons-nav-bar-dark-large"
                        key={index}
                        style={{ marginLeft: "5px" }}
                        onClick={(e) => onSportClick(e, row)}>
                        {row[0].toUpperCase() + row.slice(1)}
                    </button>))}
                </div>
                {/* {matches && <DynamicTable columns={liveMatches ? allMatches : completedMatches} data={matches} sport={sportType} liveMatches={liveMatches} />} */}
                {matches && <MatchesTable columns={liveMatches ? allMatches : completedMatches} data={matches} sport={sportType} liveMatches={liveMatches} />}
            </div>
            {/* {(role === "admin" && (liveGames || embedLink)) && (
                <div>
                    <div>
                        <GameScreen game={liveGames} gameLink={embedLink} preLive={games} logout={logout} />
                    </div>
                </div>
            )}
            {(role !== "admin" && liveGames) && (
                <div>
                    <div>
                        <GameScreen2 game={liveGames} gameLink={embedLink} preLive={games} logout={logout} />
                    </div>
                </div>
            )} */}
        </div>
    );
};
